import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
    name: "userdata",
    initialState: {
        userInfo: {},
        currentRoleInfo: {},
        logoutTriggerResponse: false,
        basicBatId: "85151740",
        currentBatId: "85151740",
        activityLogDataExport: [],
        hasActivityLogExported: false,
    },
    reducers: {
        replaceUserInfo(state, action) {
            const totalData = action.payload;
            state.userInfo = totalData || {};
        },
        replaceActivityLogInfo(state, action) {
            const totalData = action.payload;
            state.activityLogDataExport = totalData.activityLogDataExport;
        },
        activityLogExportResponse(state, action) {
            state.hasActivityLogExported = action.payload;
        },
        addCurrentRole(state, action) {
            const roleDetail = action.payload;
            state.currentRoleInfo = roleDetail.role;
        },
        removeCurrentRole(state) {
            state.currentRoleInfo = {};
        },
        addLogoutResponse(state) {
            state.logoutTriggerResponse = true;
        },
        updateBasicBatId(state, action) {
            state.basicBatId = action.payload;
        },
        updateCurrentBatId(state, action) {
            state.currentBatId = action.payload;
        },
    },
});

export const userDataActions = userDataSlice.actions;

export default userDataSlice;
