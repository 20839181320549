const {
    REACT_APP_ENDPOINT_FIRST,
    REACT_APP_ENDPOINT_SECOND,
    REACT_APP_ENDPOINT_THIRD,
    REACT_APP_TENANT_ID,
    REACT_APP_CLIENT_ID,
    REACT_APP_REDIRECT_URI,
    REACT_APP_API_SCOPE,
    REACT_APP_TEMP_ONE_ID,
} = process.env;

const config = {
    api: {
        endPointOne: REACT_APP_ENDPOINT_FIRST,
        endPointTwo: REACT_APP_ENDPOINT_SECOND,
        endPointThree: REACT_APP_ENDPOINT_THIRD,
        mockingEnabled: false,
        localEndPoint: REACT_APP_REDIRECT_URI,
    },
    oneIdTemp: REACT_APP_TEMP_ONE_ID,
    auth: {
        authority: "https://login.microsoftonline.com",
        tenantId: REACT_APP_TENANT_ID,
        clientId: REACT_APP_CLIENT_ID,
        redirectUri: REACT_APP_REDIRECT_URI,
        apiScope: REACT_APP_API_SCOPE,
        userScope: "user.read",
    },
};

export default config;
