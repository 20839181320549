import { createSlice } from "@reduxjs/toolkit";

const homeDataSlice = createSlice({
    name: "homedata",
    initialState: {
        items: [],
        planningPeriods: {},
        selectedSupplierInfo: {},
        hasExported: false,
        allCropPlanExportData: [],
        allDomFaExportData: [],
        allPackingPlanExportData: [],
        allThirdPartyExportData: [],
        allOpportunityStockExportData: [],
        hasAllCropPlanExported: false,
        hasAllDomFaExported: false,
        hasAllPackingPlanExported: false,
        hasAllThirdPartyExported: false,
        hasAllOpportunityStockExported: false,
    },
    reducers: {
        replaceHomeData(state, action) {
            const totalData = action.payload;
            state.items = totalData.items || [];
            state.planningPeriods = totalData.planningPeriods || [];
        },
        replaceAllPlanInfo(state, action) {
            const totalData = action.payload;
            const type = totalData.type;
            switch (type) {
                case "cropPlan":
                    state.allCropPlanExportData =
                        totalData.allCropPlanExportData;
                    break;
                case "domFa":
                    state.allDomFaExportData = totalData.allDomFaExportData;
                    break;
                case "packingPlan":
                    state.allPackingPlanExportData =
                        totalData.allPackingPlanExportData;
                    break;
                case "thirdParty":
                    state.allThirdPartyExportData =
                        totalData.allThirdPartyExportData;
                    break;
                case "opportunityStock":
                    state.allOpportunityStockExportData =
                        totalData.allOpportunityStockExportData;
                    break;
                default:
                    state.allCropPlanExportData =
                        totalData.allCropPlanExportData;
                    break;
            }
        },
        allPlanExportResponse(state, action) {
            const totalData = action.payload;
            const type = totalData.type;

            switch (type) {
                case "cropPlan":
                    state.hasAllCropPlanExported = totalData.result;
                    break;
                case "domFa":
                    state.hasAllDomFaExported = totalData.result;
                    break;
                case "packingPlan":
                    state.hasAllPackingPlanExported = totalData.result;
                    break;
                case "thirdParty":
                    state.hasAllThirdPartyExported = totalData.result;
                    break;
                case "opportunityStock":
                    state.hasAllOpportunityStockExported = totalData.result;
                    break;
                default:
                    state.hasAllCropPlanExported = totalData.result;
                    break;
            }
        },
        replaceAllDomFaInfo(state, action) {
            const totalData = action.payload;
            state.allDomFaExportData = totalData.allDomFaExportData;
        },
        replaceAllPackingPlanInfo(state, action) {
            const totalData = action.payload;
            state.allPackingPlanExportData = totalData.allPackingPlanExportData;
        },
        replaceAllThirdPartyInfo(state, action) {
            const totalData = action.payload;
            state.allThirdPartyExportData = totalData.allThirdPartyExportData;
        },
        replaceAllOpportunityStockInfo(state, action) {
            const totalData = action.payload;
            state.allOpportunityStockExportData =
                totalData.allOpportunityStockExportData;
        },

        removeSuppliers(state) {
            state.items = [];
        },
        addCurrentSupplier(state, action) {
            const currentSupplier = action.payload;
            state.selectedSupplierInfo = { ...currentSupplier };
        },
        removeAll(state) {
            state.items = [];
            state.selectedSupplierInfo = {};
            state.planningPeriods = {};
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
    },
});

export const homeDataActions = homeDataSlice.actions;

export default homeDataSlice;
