import { createSlice } from "@reduxjs/toolkit";

const userProvSlice = createSlice({
    name: "userProv",
    initialState: {
        tableData: [],
        allTableData: [],
        totalPages: 0,
        response: { name: "", type: "", message: "" },
        deleteResponse: { name: "", type: "", message: "" },
        roleList: [],
        supplierList: [],
    },
    reducers: {
        replaceUserProvData(state, action) {
            const totalData = action.payload;
            state.tableData = totalData.tableData || [];
            state.totalPages = totalData.totalPages || 0;
        },
        removeAll(state) {
            state.tableData = [];
            state.allTableData = [];
            state.response = { name: "", type: "", message: "" };
            state.deleteResponse = { name: "", type: "", message: "" };
            state.totalPages = 0;
        },
        addAllTableData(state, action) {
            const totalData = action.payload;
            state.allTableData = totalData.allTableData;
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        addDeleteResponse(state, action) {
            const { name, type, message } = action.payload;
            state.deleteResponse = { name, type, message };
        },
        removeDeleteResponse(state) {
            state.deleteResponse = { name: "", type: "", message: "" };
        },
        addUserProvRoles(state, action) {
            const totalData = action.payload;
            state.roleList = totalData.roleList || [];
        },
        addUserProvSuppliers(state, action) {
            const totalData = action.payload;
            state.supplierList = totalData.supplierList || [];
        },
    },
});

export const userProvActions = userProvSlice.actions;

export default userProvSlice;
