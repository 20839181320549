import { createSlice } from "@reduxjs/toolkit";

const historyDataSlice = createSlice({
    name: "history",
    initialState: {
        items: [],
        title: "",
    },
    reducers: {
        replaceHistoryData(state, action) {
            const totalData = action.payload;
            const historyTitle = totalData.historyTitle;
            const historyItems = totalData.historyItems;
            state.items = historyItems || [];
            state.title = historyTitle || "";
        },
        removeAll(state) {
            state.items = [];
            state.title = "";
        },
    },
});

export const historyDataActions = historyDataSlice.actions;

export default historyDataSlice;
