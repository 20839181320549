import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        showPageLoader: false,
        showTableLoader: false,
        showSearchLoader: false,
        showNotifyLoader: false,
        errorResponse: { hasError: false, message: "" },
    },
    reducers: {
        pageLoaderShow(state) {
            state.showPageLoader = true;
        },
        pageLoaderHide(state) {
            state.showPageLoader = false;
        },
        tableLoaderShow(state) {
            state.showTableLoader = true;
        },
        tableLoaderHide(state) {
            state.showTableLoader = false;
        },
        searchLoaderShow(state) {
            state.showSearchLoader = true;
        },
        searchLoaderHide(state) {
            state.showSearchLoader = false;
        },
        notifyLoaderToggle(state, action) {
            state.showNotifyLoader = action.payload;
        },
        addErrorResponse(state, action) {
            const { hasError, message } = action.payload;
            state.errorResponse = { hasError, message };
        },
        removeErrorResponse(state) {
            state.errorResponse = { hasError: false, message: "" };
        },
    },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
