import { createSlice } from '@reduxjs/toolkit';

const notificationDataSlice = createSlice({
    name: 'notification',
    initialState: {
        items: []
    },
    reducers: {
        replaceNotificationData(state, action) {
            const totalData = action.payload;
            state.items = totalData || [];
        },
        removeNotification(state, action) {
            const notificationId = action.payload;
            state.items = state.items.filter(item => item.id !== notificationId);
        }
    }
});

export const notificationDataActions = notificationDataSlice.actions;

export default notificationDataSlice;