import { createSlice } from '@reduxjs/toolkit';

const supplierInfoSlice = createSlice({
	name: 'supplierInfo',
	initialState: {
		info: {},
	},
	reducers: {
		replaceSupplierInfo(state, action) {
			const totalData = action.payload;
			state.info = Object.keys(totalData).length > 0 ? totalData : {};
		},
	},
});

export const supplierInfoActions = supplierInfoSlice.actions;

export default supplierInfoSlice;
