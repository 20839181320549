import React from 'react';
import './LoadingSpinner.style.scss';

const LoadingSpinner = () => {
	return (
		<>
			<div className="overlay">
				<div className="spanner">
					<div className="loader"></div>
					<p>Loading...</p>
				</div>
			</div>
		</>
	);
};

export default LoadingSpinner;
