/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import BatButton from '../../lib/BatButton/BatButton';
import logoImg from '../../assets/images/bat_logo.svg';
import './PageNotFound.style.scss';

const PageNotFound = ({ redirectHandler }) => {
    return (
        <div className='page-not-found-wrapper'>
            <header>
                <div className='logo-section'>
                    <Link
                        to="/"
                    >
                        <img src={logoImg} alt="logo" className="logo-image" />
                    </Link>
                </div>
            </header>
            <div className="page-not-found-outlet">
                <div className='page-not-found-content'>
                    <h1>404! Page is not available</h1>
                    <BatButton btnStyle="bat-dark-blue" click={redirectHandler}>Go To User Role Page</BatButton>
                </div>
            </div>
            <footer className="page-not-found-footer">
                <p className="ft-text">&copy; 2022 British American Tobocco</p>
            </footer>
        </div>
    )
}

export default PageNotFound