import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userDataActions } from '../../store/slices/userdata-slice';
import PageNotFound from '../../components/PageNotFound/PageNotFound';

const PageNotFoundShell = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate();

  const redirectHandler = () => {
    dispatch(userDataActions.removeCurrentRole());
		return navigate('/');
  };
  
  return (
    <>
        <PageNotFound redirectHandler={redirectHandler} />
    </>
  )
}

export default PageNotFoundShell