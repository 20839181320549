/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const packingPlanSlice = createSlice({
    name: "packingPlan",
    initialState: {
        supplierInfo: {},
        uiPermissions: {},
        innerAuth: [],
        saListData: [],
        tablePrevData: [],
        tableData: [],
        tableUpdateOnly: [],
        totalTableQty: [],
        tableDataExport: [],
        response: { name: "", type: "", message: "" },
        comment: "",
        totalPages: 0,
        currentPage: 0,
        packPlanDto: {},
        selectedSANo: "",
        isSubmitEnable: false,
        lockAllocationProps: {},
        hasExported: false,
        paginationProps: {},
    },
    reducers: {
        replacePackingPlanData(state, action) {
            const totalData = action.payload;
            state.supplierInfo = totalData.supplierInfo || {};
            state.tablePrevData = totalData.tableData || [];
            state.tableData = totalData.tableData || [];
            state.totalTableQty = totalData.totalTableQty || [];
            state.uiPermissions = totalData.uiPermissions || {};
            state.innerAuth = totalData.innerAuth || [];
            state.saListData = totalData.saListData || [];
            state.comment = totalData.comment || "";
            state.selectedSANo = totalData.selectedSANo || "";
            state.totalPages = totalData.totalPages || 0;
            state.currentPage = totalData.currentPage || 0;
            state.isSubmitEnable = totalData.isSubmitEnable || false;
            state.lockAllocationProps = totalData.lockAllocationProps || {};
            state.paginationProps = totalData.paginationProps || {};
        },
        replaceTableData(state, action) {
            const totalData = action.payload;
            state.tablePrevData = totalData.tablePrevData || [];
            state.tableData = totalData.tableData || [];
        },
        replaceDataToExport(state, action) {
            const totalData = action.payload;
            state.tableDataExport = totalData.dataToExport;
        },
        removeAll(state) {
            state.supplierInfo = {};
            state.uiPermissions = {};
            state.innerAuth = [];
            state.saListData = [];
            state.tablePrevData = [];
            state.tableData = [];
            state.totalTableQty = [];
            state.tableUpdateOnly = [];
            state.tableDataExport = [];
            state.response = { name: "", type: "", message: "" };
            state.comment = "";
            state.isSubmitEnable = false;
            state.lockAllocationProps = {};
            state.paginationProps = {};
        },
        setEnableSubmit(state) {
            state.isSubmitEnable = true;
        },
        removeUpdates(state) {
            state.tableUpdateOnly = [];
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
        updatePackPlanDifferentiator(state, action) {
            const updatedItem = action.payload;

            if (updatedItem && Object.keys(updatedItem).length > 0) {
                const currentLotValueChangeLog = {};
                const currentCalValueChangerLog = {};
                const baseUpdatedItem = state.tableData.find(
                    (eachItem) => eachItem.id === updatedItem.id
                );
                const newTableData = state.tableData.map((item) => {
                    if (item.id === updatedItem.id) {
                        item.monthWiseData.forEach((eachMonth) => {
                            currentCalValueChangerLog[eachMonth.id] =
                                eachMonth.value;
                        });
                        currentLotValueChangeLog[item.id] =
                            updatedItem.isSendToGloss;
                        return {
                            ...item,
                            isSendToGloss: updatedItem.isSendToGloss,
                            editItem: updatedItem.editItem,
                        };
                    } else {
                        currentLotValueChangeLog[item.id] = item.isSendToGloss;
                        item.monthWiseData.forEach((eachMonth) => {
                            currentCalValueChangerLog[eachMonth.id] =
                                eachMonth.value;
                        });
                        return {
                            ...item,
                        };
                    }
                });
                state.tableData = [...newTableData];

                const addCalTempDataCounter = [];
                const addLotTempDataCounter = [];

                state.tablePrevData.forEach((item) => {
                    if (
                        item.isSendToGloss != currentLotValueChangeLog[item.id]
                    ) {
                        addLotTempDataCounter.push(item.id);
                    }
                    if (item.id === updatedItem.id) {
                        item.monthWiseData.forEach((eachMonth) => {
                            if (
                                eachMonth.value !=
                                currentCalValueChangerLog[eachMonth.id]
                            ) {
                                addCalTempDataCounter.push(eachMonth.id);
                            }
                        });
                    }
                });

                if (addLotTempDataCounter.length > 0) {
                    if (state.tableUpdateOnly.length === 0) {
                        state.tableUpdateOnly = [
                            {
                                ...baseUpdatedItem,
                                isSendToGloss: updatedItem.isSendToGloss,
                                editItem: updatedItem.editItem,
                            },
                        ];
                    } else {
                        const newTableUpdateOnly = state.tableUpdateOnly.map(
                            (eachItem) => {
                                if (eachItem.id === updatedItem.id) {
                                    return {
                                        ...eachItem,
                                        isSendToGloss:
                                            updatedItem.isSendToGloss,
                                        editItem: updatedItem.editItem,
                                    };
                                } else {
                                    return {
                                        ...eachItem,
                                    };
                                }
                            }
                        );
                        state.tableUpdateOnly = [...newTableUpdateOnly];
                    }
                } else if (
                    addCalTempDataCounter.length === 0 &&
                    addLotTempDataCounter.length === 0
                ) {
                    const newTableUpdateOnly = state.tableUpdateOnly.filter(
                        (item) => item.id !== updatedItem.id
                    );
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            }
        },
        updatePackPlanCalendarData(state, action) {
            const changedData = action.payload;
            // getting only the updated part
            let getUpdateOnly = {};
            let hasDataSynced = true;
            const currentLotValueChangeLog = {};
            const currentCalValueChangerLog = {};

            const prevLotInfo = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // storing the changed data (updating the tableCurrent)
            const updatedItems = state.tableData.map((item) => {
                currentLotValueChangeLog[item.id] = item.isSendToGloss;
                if (item.id === changedData.mainId) {
                    getUpdateOnly = {
                        ...item,
                        monthWiseData: [],
                    };
                    const updatedMonths = item.monthWiseData.map(
                        (eachMonth) => {
                            const monthUniqueId = `${eachMonth.id}-${eachMonth.year}-${eachMonth.month}`;
                            if (eachMonth.id === changedData.baseId) {
                                const previousValue = eachMonth.value;
                                const currentValue = changedData.value
                                    ? changedData.value
                                    : null;

                                currentCalValueChangerLog[eachMonth.id] =
                                    currentValue
                                        ? parseFloat(currentValue)
                                        : null;

                                const updatedTableQty = state.totalTableQty.map(
                                    (eachQty) => {
                                        if (eachQty.id === item.id) {
                                            const eachTableQty =
                                                eachQty.rowTotal;
                                            const newTableQty =
                                                eachTableQty -
                                                    (previousValue -
                                                        currentValue) >=
                                                0
                                                    ? eachTableQty -
                                                      (previousValue -
                                                          currentValue)
                                                    : 0;
                                            return {
                                                ...eachQty,
                                                rowTotal: parseInt(
                                                    Math.round(newTableQty)
                                                ),
                                            };
                                        } else {
                                            return eachQty;
                                        }
                                    }
                                );
                                state.totalTableQty = updatedTableQty;

                                let hasValueEdited = false;

                                if (currentValue) {
                                    const prevLotMonth =
                                        prevLotInfo.monthWiseData.find(
                                            (eachPrevMonth) =>
                                                eachPrevMonth.id ===
                                                eachMonth.id
                                        );
                                    if (
                                        prevLotMonth.value ===
                                        parseFloat(currentValue)
                                    ) {
                                        hasValueEdited =
                                            prevLotMonth.hasValueEdited;
                                    } else {
                                        hasValueEdited = true;
                                    }
                                }

                                const updatedMonthData = {
                                    ...eachMonth,
                                    uniqueId: monthUniqueId,
                                    value: currentValue,
                                    hasValueEdited,
                                    editedRow: true,
                                };
                                getUpdateOnly.monthWiseData.push(
                                    updatedMonthData
                                );
                                return updatedMonthData;
                            } else {
                                currentCalValueChangerLog[eachMonth.id] =
                                    eachMonth.value;
                                return eachMonth;
                            }
                        }
                    );

                    return {
                        ...item,
                        monthWiseData: updatedMonths,
                    };
                } else {
                    return item;
                }
            });

            // getting the previous data
            const addCalTempDataCounter = [];
            const addLotTempDataCounter = [];

            state.tablePrevData.forEach((item) => {
                if (item.isSendToGloss != currentLotValueChangeLog[item.id]) {
                    addLotTempDataCounter.push(item.id);
                }
                if (item.id === changedData.mainId) {
                    item.monthWiseData.forEach((eachMonth) => {
                        if (
                            eachMonth.value !=
                            currentCalValueChangerLog[eachMonth.id]
                        ) {
                            addCalTempDataCounter.push(eachMonth.id);
                        }
                    });
                }
            });

            if (
                addCalTempDataCounter.length > 0 ||
                addLotTempDataCounter.length > 0
            ) {
                // setting the tableUpdateOnly
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    // checking item which is updated available or not
                    let isItemAvailable = false;
                    let newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                let isMonthAvailable = false;
                                const updatedMonths = item.monthWiseData.map(
                                    (eachMonth) => {
                                        const monthUniqueId = `${eachMonth.id}-${eachMonth.year}-${eachMonth.month}`;
                                        if (
                                            monthUniqueId ===
                                            getUpdateOnly.monthWiseData[0]
                                                .uniqueId
                                        ) {
                                            isMonthAvailable = true;
                                            return {
                                                ...eachMonth,
                                                ...getUpdateOnly
                                                    .monthWiseData[0],
                                            };
                                        } else {
                                            return eachMonth;
                                        }
                                    }
                                );
                                if (!isMonthAvailable) {
                                    updatedMonths.push(
                                        getUpdateOnly.monthWiseData[0]
                                    );
                                }
                                return {
                                    ...item,
                                    monthWiseData: updatedMonths,
                                };
                            } else {
                                return item;
                            }
                        }
                    );

                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }

                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else if (
                addCalTempDataCounter.length === 0 &&
                addLotTempDataCounter.length === 0
            ) {
                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== getUpdateOnly.id
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }

            // checking data are synced or not
            updatedItems.forEach((item) => {
                // getting the specific totalTableQty
                const tableTotalBase = state.totalTableQty.find(
                    (eachQty) => eachQty.id === item.id
                );
                if (item.totalQty !== tableTotalBase.rowTotal) {
                    hasDataSynced = false;
                }
            });

            state.tableData = updatedItems;
            state.isSubmitEnable = hasDataSynced;
        },
    },
});

export const packingPlanActions = packingPlanSlice.actions;

export default packingPlanSlice;
