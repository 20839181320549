/* eslint-disable react/prop-types */
import React from "react";
import "./BatButton.style.scss";

const BatButton = ({
    btnType = "button",
    btnStyle = "bat-basic",
    btnWidth = "",
    click,
    isDisable = false,
    iconInfo = {},
    children = "Button",
    testId = "",
}) => {
    return (
        <>
            <button
                className={`btn ${btnStyle} ${btnWidth}`}
                type={btnType}
                onClick={click}
                disabled={isDisable}
                data-testid={testId ? testId : "test-button"}
            >
                {children.toUpperCase()}
            </button>
        </>
    );
};

export default BatButton;
