/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const thirdPartySlice = createSlice({
    name: "thirdParty",
    initialState: {
        supplierInfo: {},
        uiPermissions: {},
        innerAuth: [],
        tablePrevData: [],
        tableData: [],
        tableUpdateOnly: [],
        tableDataExport: [],
        response: { name: "", type: "", message: "" },
        comment: "",
        totalPages: 0,
        currentPage: 0,
        categoryOfSale: [],
        companiesData: [],
        tableCurrentData: [],
        availableLotList: [],
        lotPermissions: {},
        lotManageResponse: { name: "", type: "", message: "" },
        lockAllocationProps: {},
        hasExported: false,
        paginationProps: {},
    },
    reducers: {
        replaceThirdPartyData(state, action) {
            const totalData = action.payload;
            state.supplierInfo = totalData.supplierInfo || {};
            state.uiPermissions = totalData.uiPermissions || {};
            state.innerAuth = totalData.innerAuth || [];
            state.tablePrevData = totalData.tableData || [];
            state.tableData = totalData.tableData || [];
            state.comment = totalData.comment || "";
            state.totalPages = totalData.totalPages || 0;
            state.currentPage = totalData.currentPage || 0;
            state.categoryOfSale = totalData.categoryOfSale || [];
            state.lockAllocationProps = totalData.lockAllocationProps || {};
            state.paginationProps = totalData.paginationProps || {};
        },
        removeUpdates(state) {
            state.tableUpdateOnly = [];
        },
        replaceDataToExport(state, action) {
            const totalData = action.payload;
            state.tableDataExport = totalData.dataToExport;
        },
        removeAll(state) {
            state.supplierInfo = {};
            state.uiPermissions = {};
            state.innerAuth = [];
            state.tablePrevData = [];
            state.tableData = [];
            state.tableUpdateOnly = [];
            state.tableDataExport = [];
            state.response = { name: "", type: "", message: "" };
            state.comment = "";
            state.lockAllocationProps = {};
            state.paginationProps = {};
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        replaceThirdPartyCompaniesData(state, action) {
            const totalData = action.payload;
            state.companiesData = totalData.companiesData || [];
        },
        replaceThirdPartyLot(state, action) {
            const totalData = action.payload;
            state.availableLotList = totalData.availableLotList;
            state.lotPermissions = totalData.lotPermissions;
        },
        addDeleteLotResponse(state, action) {
            const { name, type, message } = action.payload;
            state.lotManageResponse = { name, type, message };
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
        updateThirdPartyData(state, action) {
            const changedData = action.payload;
            let updatedData = [];
            let getUpdateOnly = {};
            const prevItem = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // If Third Party volume has changed
            if (changedData.type === "volume") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasVolumeEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.volume == changedData.value) {
                                hasVolumeEdited = prevItem.hasVolumeEdited;
                            } else {
                                hasVolumeEdited = true;
                            }
                        } else {
                            hasVolumeEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            volume: changedData.value
                                ? changedData.value
                                : null,
                            hasVolumeEdited,
                            open:
                                changedData.value - item.shipped >= 0
                                    ? changedData.value - item.shipped
                                    : 0,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }
            // If Third Party Shipped value has changed
            if (changedData.type === "shipped") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasShippedEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.shipped == changedData.value) {
                                hasShippedEdited = prevItem.hasShippedEdited;
                            } else {
                                hasShippedEdited = true;
                            }
                        } else {
                            hasShippedEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            shipped: changedData.value
                                ? changedData.value
                                : null,
                            hasShippedEdited,
                            open:
                                item.volume - changedData.value >= 0
                                    ? item.volume - changedData.value
                                    : 0,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Category of Sales has changed
            if (changedData.type === "category-sale") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            selectedCategoryOfSale: changedData.selectedId,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Sold to has changed
            if (changedData.type === "soldTo") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            selectedSoldTo: changedData.selectedId,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If lot number has changed
            if (changedData.type === "lot-num") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            lotNumber: changedData.value,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If grade has changed
            if (changedData.type === "grade") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            grade: changedData.value,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If cropYear has changed
            if (changedData.type === "cropYear") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            cropYear: changedData.value,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If cartonWeight has changed
            if (changedData.type === "cartonWeight") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            cartonWeight: changedData.value,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            let currentLotValueChangeLog = {};

            updatedData.forEach((item) => {
                currentLotValueChangeLog = {
                    ...currentLotValueChangeLog,
                    [item.id]: {
                        cropYear: item.cropYear,
                        cartonWeight: item.cartonWeight,
                        grade: item.grade,
                        lotNumber: item.lotNumber,
                        selectedCategoryOfSale: item.selectedCategoryOfSale,
                        selectedSoldTo: item.selectedSoldTo,
                        volume: item.volume,
                        shipped: item.shipped,
                    },
                };
            });

            let addLotTempDataCounter = 0;

            state.tablePrevData.forEach((item) => {
                if (item.id === changedData.mainId) {
                    if (
                        item.cropYear !==
                            currentLotValueChangeLog[item.id]["cropYear"] ||
                        item.cartonWeight !==
                            currentLotValueChangeLog[item.id]["cartonWeight"] ||
                        item.grade !==
                            currentLotValueChangeLog[item.id]["grade"] ||
                        item.lotNumber !==
                            currentLotValueChangeLog[item.id]["lotNumber"] ||
                        item.selectedCategoryOfSale !==
                            currentLotValueChangeLog[item.id][
                                "selectedCategoryOfSale"
                            ] ||
                        item.selectedSoldTo !==
                            currentLotValueChangeLog[item.id][
                                "selectedSoldTo"
                            ] ||
                        item.volume !=
                            currentLotValueChangeLog[item.id]["volume"] ||
                        item.shipped !=
                            currentLotValueChangeLog[item.id]["shipped"]
                    ) {
                        addLotTempDataCounter++;
                    }
                }
            });

            if (addLotTempDataCounter > 0) {
                // setting the tableUpdateOnly
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    let isItemAvailable = false;
                    const newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                return getUpdateOnly;
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else {
                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }

            state.tableData = updatedData;
        },
    },
});

export const thirdPartyActions = thirdPartySlice.actions;

export default thirdPartySlice;
