import "regenerator-runtime/runtime";
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import config from "../envConfig";

// Msal Configurations
const configAuth = {
    auth: {
        authority: `${config.auth.authority}/${config.auth.tenantId}`,
        clientId: config.auth.clientId ?? "",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
};

// Authentication Parameters
const authenticationParameters = {
    scopes: [config.auth.userScope, config.auth.apiScope ?? ""],
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(
    configAuth,
    authenticationParameters,
    options
);
