/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const domFADataSlice = createSlice({
    name: "domFA",
    initialState: {
        supplierInfo: {},
        innerAuth: [],
        uiPermissions: {},
        tablePrevData: [],
        tableCurrentData: [],
        totalTableQty: [],
        tableUpdateOnly: [],
        tableLotUpdateOnly: [],
        tableCalenderUpdateOnly: [],
        tableDataExport: [],
        recalculateMilestoneList: [],
        lotAvailableList: [],
        lotRemoveList: [],
        lotManagePermissions: {},
        lotManageResponse: { name: "", type: "", message: "" },
        response: { name: "", type: "", message: "" },
        comment: "",
        totalPages: 0,
        currentPage: 0,
        hasExported: false,
        lockAllocationProps: {},
        paginationProps: {},
    },
    reducers: {
        replaceDomFAData(state, action) {
            const totalData = action.payload;
            state.supplierInfo = totalData.supplierInfo || {};
            state.innerAuth = totalData.innerAuth || [];
            state.uiPermissions = totalData.uiPermissions || {};
            state.tablePrevData = totalData.tablePrevData || [];
            state.tableCurrentData = totalData.tableCurrentData || [];
            state.totalTableQty = totalData.totalTableQty || [];
            state.comment = totalData.comment || "";
            state.totalPages = totalData.totalPages || 0;
            state.currentPage = totalData.currentPage || 0;
            state.lockAllocationProps = totalData.lockAllocationProps || {};
            state.paginationProps = totalData.paginationProps || {};
        },
        replaceDomFATableItems(state, action) {
            const totalData = action.payload;
            state.tablePrevData = totalData.tablePrevData || [];
            state.tableCurrentData = totalData.tableCurrentData || [];
        },
        replaceDomFACurrentTableItems(state, action) {
            const totalData = action.payload;
            state.tableCurrentData = totalData || [];
        },
        replaceDomFALot(state, action) {
            const totalData = action.payload;
            state.lotAvailableList = totalData.lotAvailableList;
            state.lotRemoveList = totalData.lotRemoveList;
            state.lotManagePermissions = totalData.lotManagePermissions;
        },
        removeUpdates(state) {
            state.tableUpdateOnly = [];
            state.tableLotUpdateOnly = [];
            state.tableCalenderUpdateOnly = [];
        },
        replaceDataToExport(state, action) {
            const totalData = action.payload;
            state.tableDataExport = totalData.dataToExport;
        },
        removeAll(state) {
            state.supplierInfo = {};
            state.uiPermissions = {};
            state.innerAuth = [];
            state.tablePrevData = [];
            state.tableCurrentData = [];
            state.totalTableQty = [];
            state.tableUpdateOnly = [];
            state.tableLotUpdateOnly = [];
            state.tableCalenderUpdateOnly = [];
            state.tableDataExport = [];
            state.recalculateMilestoneList = [];
            state.lotAvailableList = [];
            state.lotRemoveList = [];
            state.lotManagePermissions = {};
            state.lotManageResponse = { name: "", type: "", message: "" };
            state.response = { name: "", type: "", message: "" };
            state.comment = "";
            state.totalPages = 0;
            state.currentPage = 0;
            state.lockAllocationProps = {};
            state.paginationProps = {};
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        addLotResponse(state, action) {
            const { name, type, message } = action.payload;
            state.lotManageResponse = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        removeLotResponse(state) {
            state.lotManageResponse = { name: "", type: "", message: "" };
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
        addRecalculateMilestone(state, action) {
            const recalcData = action.payload;
            const recalcDataId = recalcData.id;
            // checking recalcData already exists or not
            const isExists = state.recalculateMilestoneList.some(
                (item) => item.id === recalcDataId
            );
            if (isExists) {
                state.recalculateMilestoneList =
                    state.recalculateMilestoneList.filter(
                        (item) => item.id !== recalcData.id
                    );
            } else {
                const recalcDataItem = state.tableCurrentData.find(
                    (item) => item.id === recalcDataId
                );
                state.recalculateMilestoneList = [
                    ...state.recalculateMilestoneList,
                    recalcDataItem,
                ];
            }
        },
        clearRecalculateMilestone(state) {
            state.recalculateMilestoneList = [];
        },
        updateDomFALotData(state, action) {
            const changedData = action.payload;
            let updatedData = [];
            let getUpdateOnly = {};

            const prevLotInfo = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // If Customer has changed
            if (changedData.subType === "customerChange") {
                // Storing the changed data
                updatedData = state.tableCurrentData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            selectedCustomer: changedData.selectedCustomer,
                            hasLotEdited: true,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If FA Vol has changed
            if (changedData.subType === "FAVolChange") {
                // Storing the changed data
                updatedData = state.tableCurrentData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasFaVolEdited = false;
                        if (changedData.value) {
                            if (prevLotInfo.faVol === changedData.value) {
                                hasFaVolEdited = prevLotInfo.hasFaVolEdited;
                            } else {
                                hasFaVolEdited = true;
                            }
                        }

                        const updateItem = {
                            ...item,
                            faVol: changedData.value,
                            hasFaVolEdited,
                            hasLotEdited: true,
                            open: changedData.value - item.rec,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }
            // If REC Vol has changed
            if (changedData.subType === "RecVolChange") {
                // Storing the changed data
                updatedData = state.tableCurrentData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasRecEdited = false;

                        if (changedData.value) {
                            if (prevLotInfo.rec === changedData.value) {
                                hasRecEdited = prevLotInfo.hasRecEdited;
                            } else {
                                hasRecEdited = true;
                            }
                        }
                        const updateItem = {
                            ...item,
                            rec: changedData.value,
                            hasRecEdited,
                            hasLotEdited: true,
                            open: item.faVol - changedData.value,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            let currentLotValueChangeLog = {};
            const currentCalValueChangerLog = {};

            updatedData.forEach((item) => {
                currentLotValueChangeLog = {
                    ...currentLotValueChangeLog,
                    [item.id]: {
                        faVol: item.faVol,
                        rec: item.rec,
                        selectedCustomer: item.selectedCustomer,
                    },
                };
                item.monthWiseLotData.forEach((eachMonth) => {
                    currentCalValueChangerLog[eachMonth.projectionId] =
                        eachMonth.value;
                });
            });

            // getting the previous data
            let addCalTempDataCounter = 0;
            let addLotTempDataCounter = 0;

            state.tablePrevData.forEach((item) => {
                if (item.id === changedData.mainId) {
                    if (
                        item.faVol !=
                            currentLotValueChangeLog[item.id]["faVol"] ||
                        item.rec != currentLotValueChangeLog[item.id]["rec"] ||
                        item.selectedCustomer !=
                            currentLotValueChangeLog[item.id][
                                "selectedCustomer"
                            ]
                    ) {
                        addLotTempDataCounter++;
                    }
                    item.monthWiseLotData.forEach((eachMonth) => {
                        if (
                            eachMonth.value !=
                            currentCalValueChangerLog[eachMonth.projectionId]
                        ) {
                            addCalTempDataCounter++;
                        }
                    });
                }
            });

            if (addLotTempDataCounter > 0) {
                // setting the tableLotUpdateOnly
                if (state.tableLotUpdateOnly.length === 0) {
                    state.tableLotUpdateOnly = [getUpdateOnly];
                } else {
                    let isItemAvailable = false;
                    const newTableUpdateOnly = state.tableLotUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                return getUpdateOnly;
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableLotUpdateOnly = newTableUpdateOnly;
                }
                // setting the tableUpdateOnly
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    let isItemAvailable = false;
                    const newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                return getUpdateOnly;
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else if (
                addCalTempDataCounter === 0 &&
                addLotTempDataCounter === 0
            ) {
                const newTableLotUpdateOnly = state.tableLotUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableLotUpdateOnly = newTableLotUpdateOnly;

                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }

            state.tableCurrentData = updatedData;
        },
        updateDomFACalenderData(state, action) {
            const changedData = action.payload;
            // getting only the updated part
            let getUpdateOnly = {};
            const currentLotValueChangeLog = {};
            const currentCalValueChangerLog = {};

            const prevLotInfo = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // storing the changed data (updating the tableCurrent)
            const updatedItems = state.tableCurrentData.map((item) => {
                currentLotValueChangeLog[item.id] = item.open;

                if (item.id === changedData.mainId) {
                    getUpdateOnly = {
                        ...item,
                        monthWiseLotData: [],
                    };
                    const updatedMonths = item.monthWiseLotData.map(
                        (eachMonth) => {
                            const monthUniqueId = `${item.id}-${item.lotNumber}-${eachMonth.projectionId}-${eachMonth.year}-${eachMonth.month}`;
                            if (monthUniqueId === changedData.uniqueId) {
                                const previousValue = eachMonth.value;
                                const currentValue = changedData.value
                                    ? changedData.value
                                    : null;

                                currentCalValueChangerLog[
                                    eachMonth.projectionId
                                ] = currentValue
                                    ? parseFloat(currentValue)
                                    : null;

                                const updatedTableQty = state.totalTableQty.map(
                                    (eachQty) => {
                                        if (eachQty.id === item.id) {
                                            const eachTableQty =
                                                eachQty.rowTotal;
                                            const newTableQty =
                                                eachTableQty -
                                                    (previousValue -
                                                        currentValue) >=
                                                0
                                                    ? eachTableQty -
                                                      (previousValue -
                                                          currentValue)
                                                    : 0;
                                            return {
                                                ...eachQty,
                                                rowTotal: parseFloat(
                                                    newTableQty.toFixed(2)
                                                ),
                                            };
                                        } else {
                                            return eachQty;
                                        }
                                    }
                                );
                                state.totalTableQty = updatedTableQty;

                                let hasEdited = false;

                                if (currentValue) {
                                    const prevLotMonth =
                                        prevLotInfo.monthWiseLotData.find(
                                            (eachPrevMonth) =>
                                                eachPrevMonth.projectionId ===
                                                eachMonth.projectionId
                                        );
                                    if (
                                        prevLotMonth.value ===
                                        parseFloat(currentValue)
                                    ) {
                                        hasEdited = prevLotMonth.hasEdited;
                                    } else {
                                        hasEdited = true;
                                    }
                                }

                                const updatedMonthData = {
                                    ...eachMonth,
                                    uniqueId: monthUniqueId,
                                    value: currentValue,
                                    hasEdited,
                                };
                                getUpdateOnly.monthWiseLotData.push(
                                    updatedMonthData
                                );
                                return updatedMonthData;
                            } else {
                                currentCalValueChangerLog[
                                    eachMonth.projectionId
                                ] = eachMonth.value;
                                return eachMonth;
                            }
                        }
                    );

                    return {
                        ...item,
                        monthWiseLotData: updatedMonths,
                    };
                } else {
                    return item;
                }
            });

            // getting the previous data
            let addCalTempDataCounter = 0;
            let addLotTempDataCounter = 0;

            state.tablePrevData.forEach((item) => {
                if (item.id === changedData.mainId) {
                    if (item.open != currentLotValueChangeLog[item.id]) {
                        addLotTempDataCounter++;
                    }
                    item.monthWiseLotData.forEach((eachMonth) => {
                        if (
                            eachMonth.value !=
                            currentCalValueChangerLog[eachMonth.projectionId]
                        ) {
                            addCalTempDataCounter++;
                        }
                    });
                }
            });

            if (addCalTempDataCounter > 0) {
                if (state.tableCalenderUpdateOnly.length === 0) {
                    // setting the tableCalenderUpdateOnly
                    state.tableCalenderUpdateOnly = [getUpdateOnly];
                } else {
                    // checking item which is updated available or not
                    let isItemAvailable = false;
                    const newTableCalUpdateOnly =
                        state.tableCalenderUpdateOnly.map((item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                let isMonthAvailable = false;
                                const updatedMonths = item.monthWiseLotData.map(
                                    (eachMonth) => {
                                        const monthUniqueId = `${item.id}-${item.lotNumber}-${eachMonth.projectionId}-${eachMonth.year}-${eachMonth.month}`;
                                        if (
                                            monthUniqueId ===
                                            getUpdateOnly.monthWiseLotData[0]
                                                .uniqueId
                                        ) {
                                            isMonthAvailable = true;
                                            return {
                                                ...eachMonth,
                                                ...getUpdateOnly
                                                    .monthWiseLotData[0],
                                            };
                                        } else {
                                            return eachMonth;
                                        }
                                    }
                                );
                                if (!isMonthAvailable) {
                                    updatedMonths.push(
                                        getUpdateOnly.monthWiseLotData[0]
                                    );
                                }
                                return {
                                    ...item,
                                    total: getUpdateOnly.total,
                                    monthWiseLotData: updatedMonths,
                                };
                            } else {
                                return item;
                            }
                        });
                    if (!isItemAvailable) {
                        newTableCalUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableCalenderUpdateOnly = newTableCalUpdateOnly;
                }

                // setting the tableUpdateOnly
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    // checking item which is updated available or not
                    let isItemAvailable = false;
                    // if available
                    const newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                let isMonthAvailable = false;
                                const updatedMonths = item.monthWiseLotData.map(
                                    (eachMonth) => {
                                        const monthUniqueId = `${item.id}-${item.lotNumber}-${eachMonth.projectionId}-${eachMonth.year}-${eachMonth.month}`;
                                        if (
                                            monthUniqueId ===
                                            getUpdateOnly.monthWiseLotData[0]
                                                .uniqueId
                                        ) {
                                            isMonthAvailable = true;
                                            return {
                                                ...eachMonth,
                                                ...getUpdateOnly
                                                    .monthWiseLotData[0],
                                            };
                                        } else {
                                            return eachMonth;
                                        }
                                    }
                                );
                                if (!isMonthAvailable) {
                                    updatedMonths.push(
                                        getUpdateOnly.monthWiseLotData[0]
                                    );
                                }
                                return {
                                    ...item,
                                    total: getUpdateOnly.total,
                                    monthWiseLotData: updatedMonths,
                                };
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else if (
                addCalTempDataCounter === 0 &&
                addLotTempDataCounter === 0
            ) {
                const newTableCalUpdateOnly =
                    state.tableCalenderUpdateOnly.filter(
                        (item) => item.id !== changedData.mainId
                    );
                state.tableCalenderUpdateOnly = newTableCalUpdateOnly;

                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }

            state.tableCurrentData = updatedItems;
        },
        resetLotChanges(state, action) {
            const lotId = action.payload;
            // getting the pervious data of the specific lot
            const prevData = state.tablePrevData.find(
                (item) => item.id === lotId
            );
            if (Object.keys(prevData).length > 0) {
                const prevSelectedCustomer = prevData.selectedCustomer;
                const prevFaVol = prevData.faVol;
                const prevRec = prevData.rec;
                const prevOpen = prevFaVol - prevRec;

                //Checking the item previously updated or not
                const prevUpdatedLot = state.tableLotUpdateOnly.find(
                    (item) => item.id === lotId
                );

                if (prevUpdatedLot && Object.keys(prevUpdatedLot).length > 0) {
                    // removing the specific item from tableLotUpdateOnly
                    const newTableLotUpdateOnly =
                        state.tableLotUpdateOnly.filter(
                            (item) => item.id !== lotId
                        );
                    state.tableLotUpdateOnly = newTableLotUpdateOnly;

                    // updating the specific item from tableUpdateOnly
                    let getUpdateOnly = {};
                    state.tableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === lotId) {
                                const updateItem = {
                                    ...item,
                                    selectedCustomer: prevSelectedCustomer,
                                    faVol: prevFaVol,
                                    hasFaVolEdited: false,
                                    rec: prevRec,
                                    hasRecEdited: false,
                                    open: prevOpen,
                                };
                                getUpdateOnly = updateItem;
                                return updateItem;
                            } else {
                                return item;
                            }
                        }
                    );

                    // Finally updating the tableCurrentData
                    state.tableCurrentData = state.tableCurrentData.map(
                        (item) => {
                            if (item.id === lotId) {
                                return getUpdateOnly;
                            } else {
                                return item;
                            }
                        }
                    );
                }
            }
        },
    },
});

export const domFaActions = domFADataSlice.actions;

export default domFADataSlice;
