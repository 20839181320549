/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const opportunityStockSlice = createSlice({
    name: "opportunityStock",
    initialState: {
        supplierInfo: {},
        uiPermissions: {},
        innerAuth: [],
        tablePrevData: [],
        tableData: [],
        tableDataExport: [],
        tableUpdateOnly: [],
        response: { name: "", type: "", message: "" },
        comment: "",
        totalPages: 0,
        currentPage: 0,
        linerType: [],
        packType: [],
        planningPeriod: [],
        selectedPlanningPeriod: {},
        lockAllocationProps: {},
        hasExported: false,
        paginationProps: {},
    },
    reducers: {
        replaceOpportunityStockData(state, action) {
            const totalData = action.payload;
            state.supplierInfo = totalData.supplierInfo || {};
            state.uiPermissions = totalData.uiPermissions || {};
            state.innerAuth = totalData.innerAuth || [];
            state.tablePrevData = totalData.tableData || [];
            state.tableData = totalData.tableData || [];
            state.comment = totalData.comment || "";
            state.totalPages = totalData.totalPages || 0;
            state.lockAllocationProps = totalData.lockAllocationProps || {};
            state.paginationProps = totalData.paginationProps || {};
        },
        replaceLinerType(state, action) {
            const totalData = action.payload;
            state.linerType = totalData.linerType;
        },
        replacePackType(state, action) {
            const totalData = action.payload;
            state.packType = totalData.packType;
        },
        replaceDataToExport(state, action) {
            const totalData = action.payload;
            state.tableDataExport = totalData.dataToExport;
        },
        removeAll(state) {
            state.supplierInfo = {};
            state.uiPermissions = {};
            state.innerAuth = [];
            state.tablePrevData = [];
            state.tableData = [];
            state.tableUpdateOnly = [];
            state.response = { name: "", type: "", message: "" };
            state.comment = "";
            state.tableDataExport = [];
            state.selectedPlanningPeriod = {};
            state.lockAllocationProps = {};
            state.paginationProps = {};
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        removeUpdates(state) {
            state.tableUpdateOnly = [];
        },
        addPlanningPeriod(state, action) {
            const totalData = action.payload;
            state.planningPeriod = totalData.planningPeriod;
        },
        selectPlanningPeriod(state, action) {
            const totalData = action.payload;
            state.selectedPlanningPeriod = totalData.selectedPlanningPeriod;
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
        updateOpStockData(state, action) {
            const changedData = action.payload;
            let updatedData = [];
            let getUpdateOnly = {};
            const prevItem = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // If Opt Stock volume has changed
            if (changedData.type === "volume") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasVolumeEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.volume == changedData.value) {
                                hasVolumeEdited = prevItem.hasVolumeEdited;
                            } else {
                                hasVolumeEdited = true;
                            }
                        } else {
                            hasVolumeEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            volume: changedData.value
                                ? changedData.value
                                : null,
                            hasVolumeEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Opt Stock price has changed
            if (changedData.type === "price") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasPriceEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.price == changedData.value) {
                                hasPriceEdited = prevItem.hasPriceEdited;
                            } else {
                                hasPriceEdited = true;
                            }
                        } else {
                            hasPriceEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            price: changedData.value ? changedData.value : null,
                            hasPriceEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Opt Stock netWeight has changed
            if (changedData.type === "netWeight") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasNetWeightEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.netWeight == changedData.value) {
                                hasNetWeightEdited =
                                    prevItem.hasNetWeightEdited;
                            } else {
                                hasNetWeightEdited = true;
                            }
                        } else {
                            hasNetWeightEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            netWeight: changedData.value
                                ? changedData.value
                                : null,
                            hasNetWeightEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Opt Stock tareWeight has changed
            if (changedData.type === "tareWeight") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasTareWeightEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.tareWeight == changedData.value) {
                                hasTareWeightEdited =
                                    prevItem.hasTareWeightEdited;
                            } else {
                                hasTareWeightEdited = true;
                            }
                        } else {
                            hasTareWeightEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            tareWeight: changedData.value
                                ? changedData.value
                                : null,
                            hasTareWeightEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Opt Stock nicotine has changed
            if (changedData.type === "nicotine") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasNicotineEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.nicotine == changedData.value) {
                                hasNicotineEdited = prevItem.hasNicotineEdited;
                            } else {
                                hasNicotineEdited = true;
                            }
                        } else {
                            hasNicotineEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            nicotine: changedData.value
                                ? changedData.value
                                : null,
                            hasNicotineEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If Opt Stock sugar has changed
            if (changedData.type === "sugar") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        let hasSugarEdited = false;
                        if (changedData.value && changedData.roleId !== 1) {
                            if (prevItem.sugar == changedData.value) {
                                hasSugarEdited = prevItem.hasSugarEdited;
                            } else {
                                hasSugarEdited = true;
                            }
                        } else {
                            hasSugarEdited = false;
                        }
                        const updateItem = {
                            ...item,
                            sugar: changedData.value ? changedData.value : null,
                            hasSugarEdited,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If grade has changed
            if (changedData.type === "grade") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            grade: changedData.value ? changedData.value : null,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If cropYear has changed
            if (changedData.type === "cropYear") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            cropYear: changedData.value
                                ? changedData.value
                                : null,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If packType has changed
            if (changedData.type === "packType") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            packType: changedData.selectedId,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            // If linerType has changed
            if (changedData.type === "linerType") {
                // Storing the changed data
                updatedData = state.tableData.map((item) => {
                    if (item.id === changedData.mainId) {
                        const updateItem = {
                            ...item,
                            linerType: changedData.selectedId,
                        };
                        getUpdateOnly = updateItem;
                        return updateItem;
                    } else {
                        return item;
                    }
                });
            }

            let currentLotValueChangeLog = {};

            updatedData.forEach((item) => {
                currentLotValueChangeLog = {
                    ...currentLotValueChangeLog,
                    [item.id]: {
                        cropYear: item.cropYear,
                        grade: item.grade,
                        linerType: item.linerType,
                        netWeight: item.netWeight,
                        nicotine: item.nicotine,
                        packType: item.packType,
                        price: item.price,
                        sugar: item.sugar,
                        tareWeight: item.tareWeight,
                        volume: item.volume,
                    },
                };
            });

            let addLotTempDataCounter = 0;

            state.tablePrevData.forEach((item) => {
                if (item.id === changedData.mainId) {
                    if (
                        item.cropYear !==
                            currentLotValueChangeLog[item.id]["cropYear"] ||
                        item.grade !==
                            currentLotValueChangeLog[item.id]["grade"] ||
                        item.linerType !==
                            currentLotValueChangeLog[item.id]["linerType"] ||
                        item.netWeight !=
                            currentLotValueChangeLog[item.id]["netWeight"] ||
                        item.nicotine !=
                            currentLotValueChangeLog[item.id]["nicotine"] ||
                        item.packType !=
                            currentLotValueChangeLog[item.id]["packType"] ||
                        item.price !=
                            currentLotValueChangeLog[item.id]["price"] ||
                        item.sugar !=
                            currentLotValueChangeLog[item.id]["sugar"] ||
                        item.tareWeight !=
                            currentLotValueChangeLog[item.id]["tareWeight"] ||
                        item.volume !=
                            currentLotValueChangeLog[item.id]["volume"]
                    ) {
                        addLotTempDataCounter++;
                    }
                }
            });

            if (addLotTempDataCounter > 0) {
                // setting the tableUpdateOnly
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    let isItemAvailable = false;
                    const newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                return getUpdateOnly;
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else {
                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }
            state.tableData = updatedData;
        },
    },
});

export const opportunityStockActions = opportunityStockSlice.actions;

export default opportunityStockSlice;
