import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFoundShell from "../containers/PageNotFoundShell/PageNotFoundShell";
import LoadingSpinner from "../lib/LoadingSpinner/LoadingSpinner";

const CropPlanShell = lazy(() =>
    import("../containers/CropPlanShell/CropPlanShell")
);
const DomFAVolumeShell = lazy(() =>
    import("../containers/DomFAVolumeShell/DomFAVolumeShell")
);
const HomeShell = lazy(() => import("../containers/HomeShell/HomeShell"));
const InnerHeaderShell = lazy(() =>
    import("../containers/InnerHeaderShell/InnerHeaderShell")
);
const MainHeaderShell = lazy(() =>
    import("../containers/MainHeaderShell/MainHeaderShell")
);
const OpportunityStockShell = lazy(() =>
    import("../containers/OpportunityStockShell/OpportunityStockShell")
);
const PackingPlanShell = lazy(() =>
    import("../containers/PackingPlanShell/PackingPlanShell")
);
const ThirdPartyPlanShell = lazy(() =>
    import("../containers/ThirdPartyPlanShell/ThirdPartyPlanShell")
);
const UserRoleShell = lazy(() =>
    import("../containers/UserRoleShell/UserRoleShell")
);
const UserProvisioningShell = lazy(() =>
    import("../containers/UserProvisioningShell/UserProvisioningShell")
);

const AppRouter = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="*" element={<PageNotFoundShell />} />
                <Route path="/" element={<MainHeaderShell />}>
                    <Route index element={<UserRoleShell />} />
                    <Route path="home" element={<HomeShell />} />
                    <Route path="supplier" element={<InnerHeaderShell />}>
                        <Route index element={<CropPlanShell />} />
                        <Route
                            path="domfa-volumes"
                            element={<DomFAVolumeShell />}
                        />
                        <Route
                            path="packing-plan"
                            element={<PackingPlanShell />}
                        />
                        <Route
                            path="third-party-plan"
                            element={<ThirdPartyPlanShell />}
                        />
                        <Route
                            path="opportunity-stocks"
                            element={<OpportunityStockShell />}
                        />
                    </Route>
                    <Route
                        path="user-provisioning"
                        element={<UserProvisioningShell />}
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AppRouter;
