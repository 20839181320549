import { configureStore } from "@reduxjs/toolkit";
import homeDataSlice from "./slices/homedata-slice";
import userDataSlice from "./slices/userdata-slice";
import cropPlanDataSlice from "./slices/crop-plan-data-slice";
import supplierInfoSlice from "./slices/supplier-info-slice";
import notificationDataSlice from "./slices/notification-data-slice";
import uiSlice from "./slices/ui-slice";
import domFADataSlice from "./slices/dom-fa-data-slice";
import historyDataSlice from "./slices/history-data-slice";
import thirdPartySlice from "./slices/third-party-data-slice";
import opportunityStockSlice from "./slices/opportunity-stock-data-slice";
import packingPlanSlice from "./slices/packing-plan-data-slice";
import userProvSlice from "./slices/user-provisioning-data-slice";

const store = configureStore({
    reducer: {
        homeData: homeDataSlice.reducer,
        userData: userDataSlice.reducer,
        cropPlan: cropPlanDataSlice.reducer,
        supplierInfo: supplierInfoSlice.reducer,
        notification: notificationDataSlice.reducer,
        ui: uiSlice.reducer,
        domFA: domFADataSlice.reducer,
        history: historyDataSlice.reducer,
        packingPlan: packingPlanSlice.reducer,
        thirdParty: thirdPartySlice.reducer,
        opportunityStock: opportunityStockSlice.reducer,
        userProv: userProvSlice.reducer,
    },
});

export default store;
