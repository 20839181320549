/* eslint-disable no-unused-vars */
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { AzureAD } from "react-aad-msal";
import store from "./store/index";
import AppRouter from "./router/AppRouter";
import { authProvider } from "./services/AuthProvider";

function App() {
    const { REACT_APP_ENV } = process.env;
    return (
        <div data-testid="App">
            {REACT_APP_ENV === "local" ? (
                <BrowserRouter>
                    <Provider store={store}>
                        <AppRouter />
                    </Provider>
                </BrowserRouter>
            ) : (
                <AzureAD provider={authProvider} forceLogin>
                    <BrowserRouter>
                        <Provider store={store}>
                            <AppRouter />
                        </Provider>
                    </BrowserRouter>
                </AzureAD>
            )}
        </div>
    );
}

export default App;
