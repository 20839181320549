/* eslint-disable eqeqeq */
import { createSlice } from "@reduxjs/toolkit";

const cropPlanDataSlice = createSlice({
    name: "cropPlan",
    initialState: {
        supplierInfo: {},
        uiPermissions: {},
        innerAuth: [],
        tablePrevData: [],
        tableData: [],
        tableUpdateOnly: [],
        tableDataExport: [],
        response: { name: "", type: "", message: "" },
        resourceAvailableList: [],
        resourceRemoveList: [],
        resourcePermissions: {},
        resourceResponse: { name: "", type: "", message: "" },
        comment: "",
        totalPages: 0,
        currentPage: 0,
        lockAllocationProps: {},
        hasExported: false,
        paginationProps: {},
    },
    reducers: {
        replaceCropPlanData(state, action) {
            const totalData = action.payload;
            state.supplierInfo = totalData.supplierInfo || {};
            state.uiPermissions = totalData.uiPermissions || {};
            state.innerAuth = totalData.innerAuth || [];
            state.tablePrevData = totalData.tableData || [];
            state.tableData = totalData.tableData || [];
            state.comment = totalData.comment || "";
            state.totalPages = totalData.totalPages || 0;
            state.currentPage = totalData.currentPage || 0;
            state.lockAllocationProps = totalData.lockAllocationProps || {};
            state.paginationProps = totalData.paginationProps || {};
        },
        insertNewCropPlanData(state, action) {
            const totalData = action.payload;
            const newTableData = totalData.tableData;
            state.tableData = [...state.tableData, ...newTableData];
        },
        replaceCropPlanResource(state, action) {
            const totalData = action.payload;
            state.resourceAvailableList = totalData.resourceAvailableList || [];
            state.resourceRemoveList = totalData.resourceRemoveList || [];
            state.resourcePermissions = totalData.resourcePermissions || {};
        },
        replaceDataToExport(state, action) {
            const totalData = action.payload;
            state.tableDataExport = totalData.dataToExport;
        },
        removeAll(state) {
            state.supplierInfo = {};
            state.uiPermissions = {};
            state.innerAuth = [];
            state.tablePrevData = [];
            state.tableData = [];
            state.tableDataExport = [];
            state.tableUpdateOnly = [];
            state.response = { name: "", type: "", message: "" };
            state.resourceAvailableList = [];
            state.resourceRemoveList = [];
            state.resourcePermissions = {};
            state.resourceResponse = { name: "", type: "", message: "" };
            state.comment = "";
            state.totalPages = 0;
            state.currentPage = 0;
            state.lockAllocationProps = {};
            state.paginationProps = {};
        },
        addResponse(state, action) {
            const { name, type, message } = action.payload;
            state.response = { name, type, message };
        },
        addResourceResponse(state, action) {
            const { name, type, message } = action.payload;
            state.resourceResponse = { name, type, message };
        },
        removeResponse(state) {
            state.response = { name: "", type: "", message: "" };
        },
        removeUpdates(state) {
            state.tableUpdateOnly = [];
        },
        removeResouceResponse(state) {
            state.resourceResponse = { name: "", type: "", message: "" };
        },
        exportResponse(state, action) {
            state.hasExported = action.payload;
        },
        updateCropPlanData(state, action) {
            const changedData = action.payload;
            // getting only the updated part
            let getUpdateOnly = {};
            let currentCalValueChangerLog = {};

            const prevLotInfo = state.tablePrevData.find(
                (item) => item.id === changedData.mainId
            );

            // storing the changed data (updating the tableCurrent)
            const updatedItems = state.tableData.map((item) => {
                if (item.id === changedData.mainId) {
                    getUpdateOnly = {
                        ...item,
                        yearWisePlanningData: [],
                    };
                    const updatedYears = item.yearWisePlanningData.map(
                        (eachYear) => {
                            if (
                                eachYear.year === changedData.year &&
                                eachYear.projectionId ===
                                    changedData.projectionId
                            ) {
                                const currentValue = changedData.value
                                    ? changedData.value
                                    : null;

                                currentCalValueChangerLog = {
                                    ...currentCalValueChangerLog,
                                    [eachYear.projectionId]: {
                                        resourceSubmitted:
                                            changedData.type === "resource"
                                                ? currentValue
                                                    ? parseFloat(currentValue)
                                                    : null
                                                : eachYear.resourceSubmitted,
                                        internal:
                                            changedData.type === "internal"
                                                ? currentValue
                                                    ? parseFloat(currentValue)
                                                    : null
                                                : eachYear.internal,
                                    },
                                };

                                const prevLotYear =
                                    prevLotInfo.yearWisePlanningData.find(
                                        (eachPrevYear) =>
                                            eachPrevYear.projectionId ===
                                            eachYear.projectionId
                                    );

                                if (changedData.type === "resource") {
                                    let hasUpdatedActuals = false;
                                    if (currentValue) {
                                        if (
                                            prevLotYear.resourceSubmitted ===
                                            parseFloat(currentValue)
                                        ) {
                                            hasUpdatedActuals =
                                                prevLotYear.hasUpdatedActuals;
                                        } else {
                                            hasUpdatedActuals = true;
                                        }
                                    }
                                    const updatedActual = {
                                        ...eachYear,
                                        resourceSubmitted: currentValue,
                                        hasUpdatedActuals,
                                    };
                                    getUpdateOnly.yearWisePlanningData.push(
                                        updatedActual
                                    );
                                    return updatedActual;
                                }
                                if (changedData.type === "internal") {
                                    let hasUpdatedInternal = false;
                                    if (currentValue) {
                                        if (
                                            prevLotYear.internal ===
                                            parseFloat(currentValue)
                                        ) {
                                            hasUpdatedInternal =
                                                prevLotYear.hasUpdatedInternal;
                                        } else {
                                            hasUpdatedInternal = true;
                                        }
                                    }
                                    const updatedInternal = {
                                        ...eachYear,
                                        internal: currentValue,
                                        hasUpdatedInternal,
                                    };
                                    getUpdateOnly.yearWisePlanningData.push(
                                        updatedInternal
                                    );
                                    return updatedInternal;
                                }
                                return eachYear;
                            } else {
                                currentCalValueChangerLog = {
                                    ...currentCalValueChangerLog,
                                    [eachYear.projectionId]: {
                                        resourceSubmitted:
                                            eachYear.resourceSubmitted,
                                        internal: eachYear.internal,
                                    },
                                };
                                return eachYear;
                            }
                        }
                    );
                    return {
                        ...item,
                        yearWisePlanningData: updatedYears,
                    };
                } else {
                    return item;
                }
            });

            // getting the previous data
            let addCalTempDataCounter = 0;

            state.tablePrevData.forEach((item) => {
                if (item.id === changedData.mainId) {
                    item.yearWisePlanningData.forEach((eachYear) => {
                        if (
                            eachYear.resourceSubmitted !=
                                currentCalValueChangerLog[
                                    eachYear.projectionId
                                ]["resourceSubmitted"] ||
                            eachYear.internal !=
                                currentCalValueChangerLog[
                                    eachYear.projectionId
                                ]["internal"]
                        ) {
                            addCalTempDataCounter++;
                        }
                    });
                }
            });

            if (addCalTempDataCounter > 0) {
                if (state.tableUpdateOnly.length === 0) {
                    state.tableUpdateOnly = [getUpdateOnly];
                } else {
                    // checking item which is updated available or not
                    let isItemAvailable = false;
                    // if available
                    const newTableUpdateOnly = state.tableUpdateOnly.map(
                        (item) => {
                            if (item.id === getUpdateOnly.id) {
                                isItemAvailable = true;
                                let isYearAvailable = false;
                                const updatedYears =
                                    item.yearWisePlanningData.map(
                                        (eachYear) => {
                                            if (
                                                eachYear.year ===
                                                getUpdateOnly
                                                    .yearWisePlanningData[0]
                                                    .year
                                            ) {
                                                isYearAvailable = true;
                                                return {
                                                    ...eachYear,
                                                    ...getUpdateOnly
                                                        .yearWisePlanningData[0],
                                                };
                                            } else {
                                                return eachYear;
                                            }
                                        }
                                    );
                                if (!isYearAvailable) {
                                    updatedYears.push(
                                        getUpdateOnly.yearWisePlanningData[0]
                                    );
                                }
                                return {
                                    ...item,
                                    yearWisePlanningData: updatedYears,
                                };
                            } else {
                                return item;
                            }
                        }
                    );
                    if (!isItemAvailable) {
                        newTableUpdateOnly.push(getUpdateOnly);
                    }
                    state.tableUpdateOnly = newTableUpdateOnly;
                }
            } else {
                const newTableUpdateOnly = state.tableUpdateOnly.filter(
                    (item) => item.id !== changedData.mainId
                );
                state.tableUpdateOnly = newTableUpdateOnly;
            }

            state.tableData = updatedItems;
        },
    },
});

export const cropPlanDataActions = cropPlanDataSlice.actions;

export default cropPlanDataSlice;
